import React from 'react'
import {Box,styled} from "@mui/material"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AirIcon from '@mui/icons-material/Air';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CloudIcon from '@mui/icons-material/Cloud';


const Results = styled(Box)`

`
  





const Result = ({result}) => {
  return (
    result && Object.keys(result).length>0 ?
    <Results className='results'>
      <p><LocationOnIcon /> Location : {result.name},{result.sys.country}</p>
      <p><ThermostatIcon /> Temperature : {result.main.temp}</p>
      <p><AirIcon /> Humidity : {result.main.humidity}</p>
      <p><WbSunnyIcon /> Sunrise : {new Date(result.sys.sunrise*1000).toLocaleTimeString()}</p>
      <p><WbTwilightIcon /> Sunset : {new Date(result.sys.sunset*1000).toLocaleTimeString()}</p>
      <p><AcUnitIcon /> Climate : {result.weather[0].main}</p>
      <p><CloudIcon /> Clouds : {result.clouds.all}%</p>
    </Results>
    : <h1 className='heading' style={{
      color:'#fff',
      textAlign:'center',
      marginTop:'10rem',
      fontSize:'3rem'
    }}>Please Enter The Location</h1>
  )
}


export default Result