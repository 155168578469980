import React from 'react'
import {Box,styled} from "@mui/material"
import Img from "../assets/new.gif"
import Form from './Form'
import Result from './Result'
import { useState } from 'react'


const Component = styled(Box)(
    // {
    //     height: '100vh',
    //     display:'flex',
    //     alignItems: 'center',
    //     margin:'0 auto',
    //     width:'65%'
    // }
)

// const Image = styled(Box)({
//     backgroundImage: `url(${Img})`,
//     height:'80%',
//     width:'27%',
//     backgroundPosition:'center',
//     backgroundSize:'cover',
//     borderRadius: '20px 0 0 20px'
// })

const Gif = styled(Box)`



`


const Home = () => {

    const [result,setResult] = useState({})



  return (
    <>
    <Component className='component'>
        <Gif className='image'></Gif>


        <Box style={{
            width: '73%',
            height: '80%'
        }}>
           
            <Form setResult = {setResult} />
            <Result result={result}
            />
        </Box>
    </Component>
    </>
  )
}

export default Home