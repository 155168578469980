import axios from "axios";


const apikey = "35ff30c8e8d2482bdd162c40a4e60aa7"
const API_URL = "https://api.openweathermap.org/data/2.5/weather"

async function getWeather (city,country){



    try {
       let response = await axios.get(`${API_URL}?q=${city},${country}&appid=${apikey}&units=metric`)
       return response.data
    } catch (error) {
        console.log("Error has Occured",error.message)
        return error.response
    }
}

export default getWeather