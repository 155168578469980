import React from 'react'
import {Box,styled} from "@mui/material"
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import { useState } from 'react';
import getWeather from "../services/api"

const Nav = styled(Box)({
    
   
    
})

const Input = styled(InputBase)`
`
    



const GetButton = styled(Button)`
        
`




const Form = (props) => {

const [data,setdata] = useState({
    city:'',
    country:''
})

    
function handleChange(event){
    
    setdata({...data, [event.target.name]: event.target.value})
    console.log(data)
}

async function getWeatherInfo(){
    let response= await getWeather(data.city,data.country)
    props.setResult(response)
}

  return (
    <>

<Nav className='nav'>
    <Input 
    className='input'
    variant='standard' 
    placeholder='City'
    onChange={handleChange}
    name='city'
    />

    <Input 
    className='input'
    placeholder='Country' 
    variant='standard'
    onChange={handleChange}
    name='country'
    />

    <GetButton 
    className='button'
    variant='contained'
    onClick={getWeatherInfo}>
    Get Weather</GetButton>
</Nav>
    </>

  )
}

export default Form